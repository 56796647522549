
import Api from "../../../services/api";
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import { types } from "./W25F2018_actions";
import { delay } from "redux-saga";

export default function W25F2018Sagas() {
    return [
        watchGetListCandicate(),
        watchUpdateInterviewConfirm(),
        watchUpdateInterviewStatus(),
        watchGetCboFilterCandidate(),
        watchGetCboInterview(),
        watchGetCboStage(),
        watchGetGridLeft(),
        watchOnDelete(),
    ];
}


export function* getCboFilterCandidate(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2018/load-cbo-candidate', data.params);
        if (response && response.data) {
            yield put({type: types.W25F2018_LOAD_CBO_FILTER_CANDIDATE_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get Cbo filter Candidate error', e);
    }

}

export function* watchGetCboFilterCandidate() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2018_LOAD_CBO_FILTER_CANDIDATE, getCboFilterCandidate);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboInterview(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w25f2018/load-cbo-interviewer');
        if (response && response.data) {
            yield put({type: types.W25F2018_LOAD_CBO_INTERVIEW_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get Cbo interview error', e);
    }
}

export function* watchGetCboInterview() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2018_LOAD_CBO_INTERVIEW, getCboInterview);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


export function* getCboStage(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2012/load-stage', data.params);
        if (response && response.data) {
            yield put({type: types.W25F2018_LOAD_CBO_STAGE_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get Cbo state error', e);
    }
}

export function* watchGetCboStage() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2018_LOAD_CBO_STAGE, getCboStage);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getListCandicate(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2018/get-list-candidate", data.params);
        if (response && response.data) {
            yield put({ type: types.W25F2018_GET_LIST_CANDICATE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get list candicate error",e);
    }
}

export function* watchGetListCandicate() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2018_GET_LIST_CANDICATE, getListCandicate
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* updateInterviewConfirm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2033/interview-confirm", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("update interview confirm error",e);
    }
}

export function* watchUpdateInterviewConfirm() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2018_UPDATE_DATA_INTERVIEW_CONFIRM, updateInterviewConfirm
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* updateInterviewStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2033/update-interview-status", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("update interview status error",e);
    }
}

export function* watchUpdateInterviewStatus() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2018_UPDATE_INTERVIEW_STATUS, updateInterviewStatus
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getGridLeft(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2018/load-grid-left", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get grid left error");
    }
}

export function* watchGetGridLeft() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2018_GET_GRID_LEFT, getGridLeft
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* onDelete(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w25f2018/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete error");
    }
}

export function* watchOnDelete() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2018_ON_DELETE, onDelete
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
