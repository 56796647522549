/**
 * @copyright 2022 @ DigiNet
 * @author THANHAN
 * @create 20/10/2022
 * @Example
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W13F1003_actions";
import { delay } from "redux-saga";

export default function W13F1003Sagas() {
    return [
        watchLoadGrid(),
        watchApproval(),
        watchCheck(),
        watchApprovalCancel(),
    ];
}

export function* loadGrid(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w13f1003/load-form", data.params);
        if (res && res.data) {
            yield put({
                type: types.W13F1003_LOAD_GRID_SUCCESS,
                data: res.data,
            });
            data.cb && data.cb(null, res.data);
        return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("load grid error");
    }
}

export function* watchLoadGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W13F1003_LOAD_GRID, loadGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* approval(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w13f1003/approval", data.params);
        if (res && res.data) {
            yield put({
                type: types.W13F1003_APPROVAL_SUCCESS,
                data: res.data,
            });
            data.cb && data.cb(null, res.data);
        return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("approval error")
    }
}

export function* watchApproval() {
    while (true) {
        const watcher = yield takeLatest(types.W13F1003_APPROVAL, approval);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* approvalCancel() {
    try {
        yield delay(300);
        yield put({type: types.W13F1003_APPROVAL_CANCEL_SUCCESS,});
    } catch (e) {
        console.log("approval cancel error")
    }
}

export function* watchApprovalCancel() {
    while (true) {
        const watcher = yield takeLatest(types.W13F1003_APPROVAL_CANCEL, approvalCancel);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* check(data) {
    try {
        yield delay(300);
        for (let i = 0; i < data.params.arrCheckParams.length; i++) {
            const response = yield Api.put('/w13f1003/check', data.params.arrCheckParams[i]);
            if (response && response.data) {
                yield put({ type: types.W29F2309_CHECK_SUCCESS, data: response.data });
                data.cb && data.cb(null, response.data);
                if(response.data.Status === 0){
                    const approvalResponse = yield Api.put('/w13f1003/approval', data.params.arrApprovalParams[i]);
                    if (approvalResponse && approvalResponse.data) {
                        yield put({ type: types.W29F2309_APPROVAL_SUCCESS, data: approvalResponse.data });
                        data.cb && data.cb(null, approvalResponse.data)
                    }
                    else {
                        data.cb && data.cb(approvalResponse, null);
                    }
                }
                else if (response.data.Status === 1 && response.data.MsgAsk === 1){
                    yield take();
                }
            }
            else {
                data.cb && data.cb(response, null)
            }
        }
    }
    catch (e) {
        console.log('check error');
    }
}

export function* watchCheck() {
    while (true) {
        const watcher = yield takeLatest(types.W13F1003_CHECK, check);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}