/**
 * @copyright 2022 @ DigiNet
 * @author THANHAN
 * @create 20/10/2022
 * @Example
 */

export const types = {
    W13F1003_LOAD_GRID:"W13F1003_LOAD_GRID",
    W13F1003_LOAD_GRID_SUCCESS:"W13F1003_LOAD_GRID_SUCCESS",
    W13F1003_APPROVAL:"W13F1003_APPROVAL",
    W13F1003_APPROVAL_SUCCESS:"W13F1003_APPROVAL_SUCCESS",
    W13F1003_CHECK:"W13F1003_CHECK",
    W13F1003_CHECK_SUCCESS:"W13F1003_CHECK_SUCCESS",
    W13F1003_APPROVAL_CANCEL: "W13F1003_APPROVAL_CANCEL",
    W13F1003_APPROVAL_CANCEL_SUCCESS: "W13F1003_APPROVAL_CANCEL_SUCCESS",
};

export function loadGrid(params, cb) {
    return {
        type: types.W13F1003_LOAD_GRID,
        cb,
        params
    };
}

export function approval(params, cb) {
    return {
        type: types.W13F1003_APPROVAL,
        cb,
        params
    };
}

export function check(params, cb) {
    return {
        type: types.W13F1003_CHECK,
        cb,
        params
    };
}

export function cancelApproval() {
    return {
        type: types.W13F1003_APPROVAL_CANCEL
    }
}