import {types} from "./W25F2023_actions";

const initialState = {
    loadTabView: {},
    loadInterviewSchedule: {},
    chooseACurator: {}
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W25F2023_LOAD_TAB_VIEW_SUCCESS:
            return {
                ...state,
                loadTabView: action.data
            };
        case types.W25F2023_LOAD_INTERVIEW_SCHEDULE_SUCCESS:
            return {
                ...state,
                loadInterviewSchedule: action.data
            };
        case types.W25F2023_CHOOSE_A_CURATOR_SUCCESS:
            return {
                ...state,
                chooseACurator: action.data
            };
        default:
            return state;
    }
}
