import Api from "../../../services/api";
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import {types} from "./W25F2033_actions";
import {delay} from "redux-saga";

export default function W29F2025Sagas() {
    return [watchGetSystemMail(), watchGetMailTemplate(), watchLoadMailData(), watchGetMailTemplateRemind()];
}

export function* loadMailData(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2033/load-mail-data", data.params);
        if (response && response.data) {
            yield put({type: types.W25F2033_LOAD_MAIL_DATA_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load mail data error");
    }
}

export function* watchLoadMailData() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2033_LOAD_MAIL_DATA, loadMailData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getSystemMail(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2033/get-email-system");
        if (response && response.data) {
            yield put({type: types.W25F2033_GET_SYSTEM_MAIL_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get system mail error");
    }
}

export function* watchGetSystemMail() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2033_GET_SYSTEM_MAIL, getSystemMail);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getMailTemplate(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w25f2033/get-mail-template");
        if (response && response.data) {
            yield put({type: types.W25F2033_GET_MAIL_TEMPLATE_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get mail template error");
    }
}

export function* watchGetMailTemplate() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2033_GET_MAIL_TEMPLATE, getMailTemplate);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getMailTemplateRemind(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w25f2033/get-mail-remind-template");
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get mail template remind error");
    }
}

export function* watchGetMailTemplateRemind() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2033_GET_MAIL_TEMPLATE_REMIND, getMailTemplateRemind);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
