/**
 * @copyright 2019 @ DigiNet
 * @author ANHTAI
 * @create 5/8/2020
 * @Example
 */

import Api from '../../../services/api';
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "../W21F2010/W21F2010_actions";
import { delay } from "redux-saga";

export default function ConfirmSagas() {
    return [
        watchGetCboStatus(),
        watchGetGridConfirm(),
        watchSaveConfirm(),
    ];
}

//get cbo status..
export function* getCboStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w84f3000/load-approval-status', data.params);
        if (response && response.data) {
            yield put({ type: types.CONFIRM_GET_CBO_STATUS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo status error');
    }

}

export function* watchGetCboStatus() {
    while (true) {
        const watcher = yield takeLatest(types.CONFIRM_GET_CBO_STATUS, getCboStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get grid confirm..
export function* getGridConfirm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w21f2010/load-grid', data.params);
        if (response && response.data) {
            yield put({ type: types.CONFIRM_GET_GRID_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid health insurance error');
    }

}

export function* watchGetGridConfirm() {
    while (true) {
        const watcher = yield takeLatest(types.CONFIRM_GET_GRID, getGridConfirm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//save confirm..
export function* saveConfirm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w21f2010/save', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save confirm error');
    }
}

export function* watchSaveConfirm() {
    while (true) {
        const watcher = yield takeLatest(types.CONFIRM_SAVE, saveConfirm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}