
export const types = {
    W25F2018_GET_LIST_CANDICATE: "W25F2018_GET_LIST_CANDICATE",
    W25F2018_GET_LIST_CANDICATE_SUCCESS: "W25F2018_GET_LIST_CANDICATE_SUCCESS",
    W25F2018_UPDATE_DATA_INTERVIEW_CONFIRM: "W25F2018_UPDATE_DATA_INTERVIEW_CONFIRM",
    W25F2018_UPDATE_INTERVIEW_STATUS: "W25F2018_UPDATE_INTERVIEW_STATUS",
    W25F2018_LOAD_CBO_FILTER_CANDIDATE: "W25F2018_LOAD_CBO_FILTER_CANDIDATE",
    W25F2018_LOAD_CBO_FILTER_CANDIDATE_SUCCESS: "W25F2018_LOAD_CBO_FILTER_CANDIDATE_SUCCESS",
    W25F2018_LOAD_CBO_INTERVIEW: "W25F2018_LOAD_CBO_INTERVIEW",
    W25F2018_LOAD_CBO_INTERVIEW_SUCCESS: "W25F2018_LOAD_CBO_INTERVIEW_SUCCESS",
    W25F2018_LOAD_CBO_STAGE: "W25F2018_LOAD_CBO_STAGE",
    W25F2018_LOAD_CBO_STAGE_SUCCESS: "W25F2018_LOAD_CBO_STAGE_SUCCESS",
    W25F2018_GET_GRID_LEFT: "W25F2018_GET_GRID_LEFT",
    W25F2018_ON_DELETE: "W25F2018_ON_DELETE",

};

export function getCboFilterCandidate(params, cb) {
    return {
        type: types.W25F2018_LOAD_CBO_FILTER_CANDIDATE,
        params,
        cb
    }
}

export function getCboInterview(cb) {
    return {
        type: types.W25F2018_LOAD_CBO_INTERVIEW,
        cb
    }
}

export function getCboStage(params, cb) {
    return {
        type: types.W25F2018_LOAD_CBO_STAGE,
        params,
        cb
    }
}

export function getListCandicate(params, cb) {
    return {
        type: types.W25F2018_GET_LIST_CANDICATE,
        params,
        cb
    }
}

export function updateInterviewConfirm(params, cb) {
    return {
        type: types.W25F2018_UPDATE_DATA_INTERVIEW_CONFIRM,
        params,
        cb
    }
}

export function updateInterviewStatus(params, cb) {
    return {
        type: types.W25F2018_UPDATE_INTERVIEW_STATUS,
        params,
        cb
    }
}

export function getGridLeft(params, cb) {
    return {
        type: types.W25F2018_GET_GRID_LEFT,
        params,
        cb
    }
}

export function onDelete(params, cb) {
    return {
        type: types.W25F2018_ON_DELETE,
        params,
        cb
    }
}
