export const types = {
    CONFIRM_GET_CBO_STATUS: 'CONFIRM_GET_CBO_STATUS',
    CONFIRM_GET_CBO_STATUS_SUCCESS: 'CONFIRM_GET_CBO_STATUS_SUCCESS',
    CONFIRM_GET_GRID: 'CONFIRM_GET_GRID',
    CONFIRM_GET_GRID_SUCCESS: 'CONFIRM_GET_GRID_SUCCESS',
    CONFIRM_SAVE: 'CONFIRM_SAVE',
};

export function getCboStatus(params, cb) {
    return {
        type: types.CONFIRM_GET_CBO_STATUS,
        params,
        cb
    }
}

export function getGridConfirm(params, cb) {
    return {
        type: types.CONFIRM_GET_GRID,
        params,
        cb
    }
}

export function saveConfirm(params, cb) {
    return {
        type: types.CONFIRM_SAVE,
        params,
        cb
    }
}

