import { types } from "./W25F2303_actions";

const initialState = {
    getCboCandidate: [],
    getListInterviews: [],
    getCboMailTemplate: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W25F2303_LOAD_CBO_CANDIDATE_SUCCESS:
            return {
                ...state,
                getCboCandidate: action.data
            };
        case types.W25F2303_GET_LIST_INTERVIEW_SUCCESS:
            return {
                ...state,
                getListInterviews: action.data
            };
        case types.W25F2303_GET_CBO_MAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                getCboMailTemplate: action.data
            };
        default:
            return state;
    }
}