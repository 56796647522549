/**
 * @copyright 2021 @ DigiNet
 * @author QUOCUY
 * @create 5/11/2020
 * @Example
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { delay } from "redux-saga";
import { types } from "./W25F2040_actions";

export default function W09F2040Sagas() {
    return [
        watchGetSalaryCaption(),
        watchGetCoefficientCaption(),
        watchGetCboRecruitmentFile(),
        watchGetCboDirectManager(),
        watchGetCboTrialPeriod(),
        watchGetCboSalaryGroup(),
        watchLoadVoucher(),
        watchAdd(),
        watchEdit(),
        watchCheckHideSalOfferRI(),
    ];
}

export function* getSalaryCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2033/load-base-salary-caption");
        if (response && response.data) {
            yield put({ type: types.W25F2040_GET_SALARY_CAPTION_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get error");
    }
}

export function* watchGetSalaryCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2040_GET_SALARY_CAPTION, getSalaryCaption);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCoefficientCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2033/Load-salcoefficient-caption");
        if (response && response.data) {
            yield put({ type: types.W25F2040_GET_COEFFICIENT_CAPTION_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get error");
    }
}

export function* watchGetCoefficientCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2040_GET_COEFFICIENT_CAPTION, getCoefficientCaption);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboTrialPeriod(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2010/load-cbo-status-believe", data.params);
        if (response && response.data) {
            yield put({ type: types.W25F2040_GET_CBO_TRIAL_PERIOD_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get error");
    }
}

export function* watchGetCboTrialPeriod() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2040_GET_CBO_TRIAL_PERIOD, getCboTrialPeriod);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboSalaryGroup(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w25f2040/load-combo-salary-group");
        if (response && response.data) {
            yield put({ type: types.W25F2040_GET_CBO_SALARY_GROUP_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get error");
    }
}

export function* watchGetCboSalaryGroup() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2040_GET_CBO_SALARY_GROUP, getCboSalaryGroup);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getCboDirectManager(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f1500/load-direct-manager", data.params);
        if (response && response.data) {
            yield put({ type: types.W25F2040_GET_CBO_DIRECT_MANAGER_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get error");
    }
}

export function* watchGetCboDirectManager() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2040_GET_CBO_DIRECT_MANAGER, getCboDirectManager);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboRecruitmentFile(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2040/load-cbo-offer-recruitment", data.params);
        if (response && response.data) {
            yield put({ type: types.W25F2040_GET_CBO_RECRUITMENT_FILE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get error");
    }
}

export function* watchGetCboRecruitmentFile() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2040_GET_CBO_RECRUITMENT_FILE, getCboRecruitmentFile);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadVoucher(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2040/load-voucher", data.params);
        if (response && response.data) {
            yield put({ type: types.W25F2040_LOAD_VOUCHER_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get error");
    }
}

export function* watchLoadVoucher() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2040_LOAD_VOUCHER, loadVoucher);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* add(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/w25f2040/add", data.params);
        if (response && response.data) {
            yield put({ type: types.W25F2040_ADD_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get error");
    }
}

export function* watchAdd() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2040_ADD, add);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* edit(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2040/edit", data.params);
        if (response && response.data) {
            yield put({ type: types.W25F2040_EDIT_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get error");
    }
}

export function* watchEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2040_EDIT, edit);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* checkHideSalOfferRI(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w75f8000/check-hide-sal");
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get check hide offer ri error");
    }
}

export function* watchCheckHideSalOfferRI() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2040_CHECK_HIDE_SAL_OFFER_RI, checkHideSalOfferRI);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}