/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 5/5/2020
 * @Example
 */

export const types = {
  W21F2005_GET_GRID:"W21F2005_GET_GRID",
  W21F2005_GET_GRID_SUCCESS: "W21F2005_GET_GRID_SUCCESS",
  W21F2005_GET_GRANT_TYPE:"W21F2005_GET_GRANT_TYPE",
  W21F2005_GET_GRANT_TYPE_SUCCESS: "W21F2005_GET_GRANT_TYPE_SUCCESS",
  W21F2005_GET_TYPES:"W21F2005_GET_TYPES",
  W21F2005_GET_TYPES_SUCCESS: "W21F2005_GET_TYPES_SUCCESS",
};

export function getGrid(params,cb) {
  return {
    type: types.W21F2005_GET_GRID,
    cb,
    params
  };
}

export function getCboGrantType(params,cb) {
  return {
    type: types.W21F2005_GET_GRANT_TYPE,
    cb,
    params
  };
}

export function getCboTypes(params,cb) {
  return {
    type: types.W21F2005_GET_TYPES,
    cb,
    params
  };
}


