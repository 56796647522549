import { types } from "./W25F1000_actions";

const initialState = {
    getCboRecruitmentChanels: [],
    getCboTabWorkFlow: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W25F1000_GET_CBO_RECRUITMENT_CHANEL_SUCCESS:
            return {
                ...state,
                getCboRecruitmentChanels: action.data
            };
        case types.W25F1000_GET_CBO_TAB_WORK_FLOW_SUCCESS:
            return {
                ...state,
                getCboTabWorkFlow: action.data
            };
        default:
            return state;
    }
}