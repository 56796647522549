/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/21/2020
 * @Example 
 */

export const types = {
    W25F2010_LOAD_FORM: "W25F2010_LOAD_FORM",
    W25F2010_LOAD_FORM_SUCCESS: "W25F2010_LOAD_FORM_SUCCESS",
    W25F2010_GET_LINK: "W25F2010_GET_LINK",
    W25F2010_GET_LINK_SUCCESS: "W25F2010_GET_LINK_SUCCESS",
    W25F2010_DELETE_DATA: "W25F2010_DELETE_DATA",
    W25F2010_GET_CBO_STATUS_NEWS: "W25F2010_GET_CBO_STATUS_NEWS",
    W25F2010_GET_CBO_STATUS_NEWS_SUCCESS: "W25F2010_GET_CBO_STATUS_NEWS_SUCCESS",
    W25F2010_GET_CBO_REC_INFO: "W25F2010_GET_CBO_REC_INFO",
    W25F2010_GET_CBO_REC_INFO_SUCCESS: "W25F2010_GET_CBO_REC_INFO_SUCCESS",

};

// get load form
export function getLoadForm(params, cb) {
    return {
        type: types.W25F2010_LOAD_FORM,
        params,
        cb
    }
}

// get link
export  function getLink(cb) {
    return {
        type: types.W25F2010_GET_LINK,
        cb
    }
}


// delete
export  function deleteData(params,cb) {
    return {
        type: types.W25F2010_DELETE_DATA,
        params,
        cb
    }
}

// get cbo status new
export  function getCboStatusNew(params,cb) {
    return {
        type: types.W25F2010_GET_CBO_STATUS_NEWS,
        params,
        cb
    }
}

// get cbo rec info
export  function getCboRecInfo(params, cb) {
    return {
        type: types.W25F2010_GET_CBO_REC_INFO,
        params,
        cb
    }
}

