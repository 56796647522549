export const types = {
    W25F2033_GET_SYSTEM_MAIL:"W25F2033_GET_SYSTEM_MAIL",
    W25F2033_GET_SYSTEM_MAIL_SUCCESS:"W25F2033_GET_SYSTEM_MAIL_SUCCESS",
    W25F2033_GET_MAIL_TEMPLATE:"W25F2033_GET_MAIL_TEMPLATE",
    W25F2033_GET_MAIL_TEMPLATE_SUCCESS:"W25F2033_GET_MAIL_TEMPLATE_SUCCESS",
    W25F2033_GET_MAIL_TEMPLATE_REMIND:"W25F2033_GET_MAIL_TEMPLATE_REMIND",
    W25F2033_GET_MAIL_TEMPLATE_REMIND_SUCCESS:"W25F2033_GET_MAIL_TEMPLATE_REMIND_SUCCESS",
    W25F2033_LOAD_MAIL_DATA: "W25F2033_LOAD_MAIL_DATA",
    W25F2033_LOAD_MAIL_DATA_SUCCESS: "W25F2033_LOAD_MAIL_DATA_SUCCESS"
};

export function loadMailData(params,cb) {
    return{
        type: types.W25F2033_LOAD_MAIL_DATA,
        params,
        cb
    }
}

export function getSystemMail(cb) {
    return{
        type: types.W25F2033_GET_SYSTEM_MAIL,
        cb
    }
}

export function getMailTemplate(cb) {
    return{
        type: types.W25F2033_GET_MAIL_TEMPLATE,
        cb
    }
}

export function getMailTemplateRemind(cb) {
    return{
        type: types.W25F2033_GET_MAIL_TEMPLATE_REMIND,
        cb
    }
}
