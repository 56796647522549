import Api from "../../../services/api";
import {put, cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "./W25F2023_actions";
import {delay} from "redux-saga";

export default function W25F2023Sagas() {
    return [
        watchLoadTabView(),
        watchLoadInterviewSchedule(),
        watchChooseACurator(),
    ];
}

export function* loadTabView(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2023/load-tab-view", data.params);
        if (response && response.data) {
            yield put({
                type: types.W25F2023_LOAD_TAB_VIEW_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load form error");
    }
}

export function* watchLoadTabView() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2023_LOAD_TAB_VIEW, loadTabView);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadInterviewSchedule(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2023/load-interview-schedule", data.params);
        if (response && response.data) {
            yield put({
                type: types.W25F2023_LOAD_INTERVIEW_SCHEDULE_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load form error");
    }
}

export function* watchLoadInterviewSchedule() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2023_LOAD_INTERVIEW_SCHEDULE, loadInterviewSchedule);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* chooseACurator(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2023/choose-curator", data.params);
        if (response && response.data) {
            yield put({
                type: types.W25F2023_CHOOSE_A_CURATOR_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load form error");
    }
}

export function* watchChooseACurator() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2023_CHOOSE_A_CURATOR, chooseACurator);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
