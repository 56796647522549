export const types = {
    W25F1000_GET_CBO_RECRUITMENT_CHANEL: "W25F1000_GET_CBO_RECRUITMENT_CHANEL",
    W25F1000_GET_CBO_RECRUITMENT_CHANEL_SUCCESS: "W25F1000_GET_CBO_RECRUITMENT_CHANEL_SUCCESS",
    W25F1000_GET_DATA_POPUP: "W25F1000_GET_DATA_POPUP",
    W25F1000_GET_CBO_TAB_WORK_FLOW: "W25F1000_GET_CBO_TAB_WORK_FLOW",
    W25F1000_GET_CBO_TAB_WORK_FLOW_SUCCESS: "W25F1000_GET_CBO_TAB_WORK_FLOW_SUCCESS",
    W25F1000_ADD_DATA: "W25F1000_ADD_DATA",
    W25F1000_EDIT_DATA: "W25F1000_EDIT_DATA",
    W25F1000_CONFIRM_DATA: "W25F1000_CONFIRM_DATA",
};

export function getCboRecruitmentChanel(cb) {
    return {
        type: types.W25F1000_GET_CBO_RECRUITMENT_CHANEL,
        cb,
    };
}

export function getCboTabWorkFlow(params, cb) {
    return {
        type: types.W25F1000_GET_CBO_TAB_WORK_FLOW,
        params,
        cb,
    };
}


export function getDataPopup(params,cb) {
    return {
        type: types.W25F1000_GET_DATA_POPUP,
        params,
        cb,
    };
}

export function add(params,cb) {
    return {
        type: types.W25F1000_ADD_DATA,
        params,
        cb,
    };
}

export function edit(params,cb) {
    return {
        type: types.W25F1000_EDIT_DATA,
        params,
        cb,
    };
}

export function confirm(params,cb) {
    return {
        type: types.W25F1000_CONFIRM_DATA,
        params,
        cb,
    };
}
