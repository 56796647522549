/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 6/25/2020
 * @Example 
 */

import {types} from "./W25F6000_actions";

const initialState = {
    getCboRecruimentChanel: [],

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W25F6000_GET_CBO_RECRUITMENT_CHANEL_SUCCESS: {
            return {...state, getCboRecruimentChanel: action.data};
        }

        default:
            return state;
    }
}
