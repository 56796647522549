export const types = {
    W25F2303_LOAD_CBO_CANDIDATE: "W25F2303_LOAD_CBO_CANDIDATE",
    W25F2303_GET_LIST_INTERVIEW: "W25F2303_GET_LIST_INTERVIEW",
    W25F2303_GET_LIST_INTERVIEW_SUCCESS: "W25F2303_GET_LIST_INTERVIEW_SUCCESS",
    W25F2303_SAVE : "W25F2303_SAVE",
    W25F2303_GET_DATA_FORM : "W25F2303_GET_DATA_FORM",
    W25F2303_CONFIRM: "W25F2303_CONFIRM",
    W25F2303_GET_CBO_MAIL_TEMPLATE: "W25F2303_GET_CBO_MAIL_TEMPLATE",
    W25F2303_GET_CBO_MAIL_TEMPLATE_SUCCESS: "W25F2303_GET_CBO_MAIL_TEMPLATE_SUCCESS",
    W25F2303_CHECK_FILE: 'W25F2303_CHECK_FILE',
    W25F2303_LOAD_GRID_CANDIDATE: 'W25F2303_LOAD_GRID_CANDIDATE',
    W25F2303_SAVE_MULTIPLE: 'W25F2303_SAVE_MULTIPLE',
    W25F2303_SENDMAIL: 'W25F2303_SENDMAIL',
};

export function getCboCandidate(params, cb) {
    return {
        type: types.W25F2303_LOAD_CBO_CANDIDATE,
        params,
        cb
    }
}

export function getListInterview(cb) {
    return {
        type: types.W25F2303_GET_LIST_INTERVIEW,
        cb
    }
}


export function save(params, cb) {
    return {
        type: types.W25F2303_SAVE,
        params,
        cb
    }
}

export function sendMail(params, cb) {
    return {
        type: types.W25F2303_SENDMAIL,
        params,
        cb
    }
}

export function getDataForm(params, cb) {
    return {
        type: types.W25F2303_GET_DATA_FORM,
        params,
        cb
    }
}

export function confirm(params, cb) {
    return {
        type: types.W25F2303_CONFIRM,
        params,
        cb
    }
}

export function getCboMailTemplate(cb) {
    return {
        type: types.W25F2303_GET_CBO_MAIL_TEMPLATE,
        cb
    }
}

export function confirmEmail(params,cb) {
    return{
        type: types.W25F2303_CHECK_FILE,
        params,
        cb
    }
}

export function getListCandidate(params,cb) {
    return{
        type: types.W25F2303_LOAD_GRID_CANDIDATE,
        params,
        cb
    }
}

export function saveMultiple(params,cb) {
    return{
        type: types.W25F2303_SAVE_MULTIPLE,
        params,
        cb
    }
}
