/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 4/25/2022
 * @Example 
 */
import Api                               from '../../../services/api';
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types }                         from "./W25F4000_actions";
import { delay }                         from "redux-saga";
import Config                            from "../../../config";

export default function W25F4000Sagas() {
    return [
        watchOnExport(),
    ];
}


function checkUrlFileName(filename) {
    const specialChar = filename.search("UTF-8");
    if (specialChar !== -1) {
        const reFileName = filename.slice(specialChar + 7, filename.length);
        return decodeURIComponent(reFileName);
    }
    return filename;
}

export function* onExport(data) {
    try {
        yield delay(300);
        const response = yield Api.putExport('/report/export-datagrid-to-excel', data.params);
        if (response && response.ok) {
            let _res = null;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null)
                }
            } else {
                const content = yield response.data.blob();
                const datetime = new Date().getTime();
                const _fileName = response.filename ? checkUrlFileName(response.filename) : "data-export-hr-" + datetime + '.xls';
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        }
        else {
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log('onExport error');
    }
}

export function* watchOnExport() {
    while (true) {
        const watcher = yield takeLatest(types.W25F4000_EXPORT, onExport);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}