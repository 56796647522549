/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/31/2019
 * @Example 
 */

import Api                                        from '../../../services/api';
import {put, cancel, take, takeLatest, takeEvery} from "redux-saga/effects";
import { types }                                  from "../../W2X/W25F2011/W25F2011_actions";
import { delay }                                  from "redux-saga";

export default function W25F2011Sagas() {
    return [
        watchGetCboWorkingType(),
        watchGetForm(),
        watchGetCboCriteria(),
        // watchGetTabWorkflow(),
        watchGetCboSuggestions(),
        watchGetCboMailTemplate(),
        watchGetCboRecruitmentFile(),
        watchLoadDefault(),

        watchSaveDataInfo(),
        watchSaveQuestions(),
        watchSaveCriteria(),
        watchSaveStatus(),
        watchSaveStages(),

        watchUpdateCriteria(),
        watchUpdateStages(),
        watchUpdateApprovalOffer(),
        watchUpdateReorderStages(),

        watchDeleteCriteria(),
        watchDeleteStages(),
    ];
}

//get columns..
export function* getCboWorkingType(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w25f2011/get-working-type');
        if (response && response.data) {
            yield put({ type: types.W25F2011_GET_CBO_WORKINGTYPE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo working type error');
    }

}

export function* watchGetCboWorkingType() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_GET_CBO_WORKINGTYPE, getCboWorkingType);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get form..
export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2011/load-form', data.params);
        if (response && response.data) {
            yield put({ type: types.W25F2011_GET_FORM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get form error', e);
    }

}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeEvery(types.W25F2011_GET_FORM, getForm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo criteria..
export function* getCboCriteria(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2011/get-combo-criteria', data.params);
        if (response && response.data) {
            yield put({ type: types.W25F2011_GET_CBO_CRITERIA_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo criteria error');
    }

}

export function* watchGetCboCriteria() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_GET_CBO_CRITERIA, getCboCriteria);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

// //get tab workflow..
// export function* getTabWorkflow(data) {
//     try {
//         yield delay(300);
//         const response = yield Api.put('/w25f2011/get-tab-work-flow', data.params);
//         if (response && response.data) {
//             yield put({ type: types.W25F2011_GET_TAB_WORKFLOW_SUCCESS, data: response.data });
//             data.cb && data.cb(null, response.data)
//         }
//         else {
//             data.cb && data.cb(response, null)
//         }
//     }
//     catch (e) {
//         console.log('get tab workflow error');
//     }
//
// }
//
// export function* watchGetTabWorkflow() {
//     while (true) {
//         const watcher = yield takeLatest(types.W25F2011_GET_TAB_WORKFLOW, getTabWorkflow);
//         yield take(['LOGOUT', 'NETWORK']);
//         yield cancel(watcher);
//     }
// }

//get cbo suggestions..
export function* getCboSuggestions(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2011/get-cbo-suggestions', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo suggestions error');
    }

}

export function* watchGetCboSuggestions() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_GET_CBO_SUGGESTION, getCboSuggestions);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo suggestions..
export function* getCboMailTemplate(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w25f2033/get-mail-template');
        if (response && response.data) {
            yield put({ type: types.W25F2011_GET_CBO_MAILTEMPLATE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo Mail Template error');
    }

}

export function* watchGetCboMailTemplate() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_GET_CBO_MAILTEMPLATE, getCboMailTemplate);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo recruitment file
export function* getCboRecruitmentFile(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w25f2011/combo-proposal');
        if (response?.data) {
            yield put({ type: types.W25F2011_GET_CBO_RECRUITMENTFILE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    }
    catch (e) {
        console.log('get cbo recruitment file error');
    }
}

export function* watchGetCboRecruitmentFile() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_GET_CBO_RECRUITMENTFILE, getCboRecruitmentFile);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//load default
export function* loadDefault(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2011/load-default', data.params);
        if (response?.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response.data, null);
        }
    } catch (e) {
        console.log('load default error');
    }
}

export function* watchLoadDefault() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_LOAD_DEFAULT, loadDefault);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

///////////////////////////////////////////////////////
/** SAVE DATA **/

//save data info..
export function* saveDataInfo(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2011/save-tab-info', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save data info error');
    }

}

export function* watchSaveDataInfo() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_SAVE_DATA_INFO, saveDataInfo);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//save criteria..
export function* saveCriteria(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2011/save-evaluation-criteria', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save criteria error');
    }

}

export function* watchSaveCriteria() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_SAVE_CRITERIA, saveCriteria);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//save questions..
export function* saveQuestions(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2011/save-question-content', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save questions error');
    }

}

export function* watchSaveQuestions() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_SAVE_QUESTIONS, saveQuestions);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//save status..
export function* saveStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2011/save-status', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save status error');
    }

}

export function* watchSaveStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_SAVE_STATUS, saveStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//save stages..
export function* saveStages(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2011/save-stage', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save stages error');
    }

}

export function* watchSaveStages() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_SAVE_STAGES, saveStages);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

/** END SAVE DATA **/
/////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////
/** UPDATE DATA **/

//update criteria..
export function* updateCriteria(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2011/update-evaluation-element', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save criteria error');
    }

}

export function* watchUpdateCriteria() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_UPDATE_CRITERIA, updateCriteria);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//update stages..
export function* updateStages(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2011/update-list-stage', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('update criteria error');
    }

}

export function* watchUpdateStages() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_UPDATE_STAGES, updateStages);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//update approval offer..
export function* updateApprovalOffer(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2011/update-approval-offer', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('update approval offer error');
    }

}

export function* watchUpdateApprovalOffer() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_UPDATE_APPROVALOFFER, updateApprovalOffer);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//update reorder stages..
export function* updateReorderStages(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2011/re-order-row', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('update reorder stages error');
    }

}

export function* watchUpdateReorderStages() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_UPDATE_REORDERSTAGES, updateReorderStages);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

/** END UPDATE DATA **/
/////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////
/** DELETE DATA **/

//delete criteria..
export function* deleteCriteria(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2011/delete-criteria', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('delete criteria error');
    }

}

export function* watchDeleteCriteria() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_DELETE_CRITERIA, deleteCriteria);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//delete stages..
export function* deleteStages(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2011/delete-stage', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('delete criteria error');
    }

}

export function* watchDeleteStages() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2011_DELETE_STAGES, deleteStages);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

/** END DELETE DATA **/
/////////////////////////////////////////////////////////////
