import {types} from "./W25F2033_actions";

const initialState = {
    getSystemMail: [],
    getMailTemplate: [],
    loadMailData: [],

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W25F2033_GET_SYSTEM_MAIL_SUCCESS:
            return {
                ...state,
                getSystemMail: action.data
            };
        case types.W25F2033_GET_MAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                getMailTemplate: action.data
            };
        case types.W25F2033_LOAD_MAIL_DATA_SUCCESS:
            return {
                ...state,
                loadMailData: action.data
            };
        default:
            return state;
    }
}