/**
 * @copyright 2021 @ DigiNet
 * @author QUOCUY
 * @create 5/10/2020
 * @Example
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { delay } from "redux-saga";
import { types } from "./W25F2041_actions";

export default function W09F2041Sagas() {
    return [watchGetSalaryCaption(), watchGetCoefficientCaption(), watchGetCboCandidate()];
}

export function* getSalaryCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2033/load-base-salary-caption");
        if (response && response.data) {
            yield put({ type: types.W25F2041_GET_SALARY_CAPTION_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get error");
    }
}

export function* watchGetSalaryCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2041_GET_SALARY_CAPTION, getSalaryCaption);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCoefficientCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2033/Load-salcoefficient-caption");
        if (response && response.data) {
            yield put({ type: types.W25F2041_GET_Coefficient_CAPTION_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get error");
    }
}

export function* watchGetCoefficientCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2041_GET_Coefficient_CAPTION, getCoefficientCaption);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//get candidate..
export function* getCboCandidate(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2041/get-candidate", data.params);
        if (response && response.data) {
            yield put({ type: types.W25F2041_GET_CBO_CANDIDATE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo candidate error");
    }
}

export function* watchGetCboCandidate() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2041_GET_CBO_CANDIDATE, getCboCandidate);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
