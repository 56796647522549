
import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W25F6000_actions";
import { delay } from "redux-saga";

export default function W25F2002Sagas() {
    return [
        watchGetCboRecruitmentChanel(),
        watchSearch(),
    ];
}

export function* getCboRecruitmentChanel(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w25f6000/get-cbo-recruitment-chanel", data.params);
        if (response && response.data) {
            yield put({
                type: types.W25F6000_GET_CBO_RECRUITMENT_CHANEL_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {}
}

export function* watchGetCboRecruitmentChanel() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F6000_GET_CBO_RECRUITMENT_CHANEL, getCboRecruitmentChanel
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* search(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f6000/search", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {}
}

export function* watchSearch() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F6000_SEARCH, search
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
