/**
 * @copyright 2021 @ DigiNet
 * @author QUOCUY
 * @create 5/10/2020
 * @Example
 */

import { types } from "./W25F2041_actions";

const initialState = {
    getSalaryCaption: [],
    getCoefficientCaption: [],
    getCboCandidate: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W25F2041_GET_SALARY_CAPTION_SUCCESS: {
            return { ...state, getSalaryCaption: action.data };
        }
        case types.W25F2041_GET_Coefficient_CAPTION_SUCCESS: {
            return { ...state, getCoefficientCaption: action.data };
        }
        case types.W25F2041_GET_CBO_CANDIDATE_SUCCESS:
            return {
                ...state,
                getCboCandidate: action.data,
            };
        default: {
            return state;
        }
    }
}
