/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 4/25/2022
 * @Example 
 */
export const types = {
    W25F4000_EXPORT: 'W25F4000_EXPORT',
};

export function onExport(params, cb) {
    return {
        type: types.W25F4000_EXPORT,
        params,
        cb,
    };
}