import Api from "../../../services/api";
import {put, cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "./W25F2012_actions";
import {delay} from "redux-saga";

export default function W25F2012Sagas() {
    return [
        watchLoadStage(),
        watchLoadInfoCandidate(),
        watchLoadUserEvaluation(),
        watchLoadOtherComment(),
    ];
}

export function* loadUserEvaluation(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2012/load-user-evaluation", data.params);
        if (response && response.data) {
            yield put({
                type: types.W25F2012_USER_EVALUATION_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load form error");
    }
}

export function* watchLoadUserEvaluation() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2012_USER_EVALUATION, loadUserEvaluation);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadOtherComment(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2012/load-other-user-evaluation", data.params);
        if (response && response.data) {
            yield put({
                type: types.W25F2012_OTHER_EVALUATION_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load form error");
    }
}

export function* watchLoadOtherComment() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2012_OTHER_EVALUATION, loadOtherComment);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadStage(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2012/load-stage", data.params);
        if (response && response.data) {
            yield put({
                type: types.W25F2012_LOAD_STAGE_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load form error");
    }
}

export function* watchLoadStage() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2012_LOAD_STAGE, loadStage);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadInfoCandidate(data) {
    try {
        yield delay(300);
        const response = yield Api.put(
            "/w25f2012/load-info-candidate",
            data.params
        );
        if (response && response.data) {
            yield put({
                type: types.W25F2012_LOAD_INFO_CANDIDATE_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load form error");
    }
}

export function* watchLoadInfoCandidate() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2012_LOAD_INFO_CANDIDATE,
            loadInfoCandidate
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

