export const types = {
    W25F2000_GET_CBO_APP_STATUS : "W25F2000_GET_CBO_APP_STATUS",
    W25F2000_GET_CBO_APP_STATUS_SUCCESS : "W25F2000_GET_CBO_APP_STATUS_SUCCESS",
    W25F2000_LOAD_DATA_GRID : "W25F2000_LOAD_DATA_GRID",
    W25F2000_LOAD_DATA_GRID_SUCCESS : "W25F2000_LOAD_DATA_GRID_SUCCESS",
    W25F2000_DELETE_DATA_GRID : "W25F2000_DELETE_DATA_GRID",
    W25F2000_DELETE_DATA_GRID_SUCCESS : "W25F2000_DELETE_DATA_GRID_SUCCESS",
};

export function getCboAppStatus(params, cb) {
    return {
        type : types.W25F2000_GET_CBO_APP_STATUS,
        params,
        cb
    }
};

export function loadDataGrid(params, cb) {
    return {
        type: types.W25F2000_LOAD_DATA_GRID,
        params,
        cb
    }
}

export function deleteDataGrid(params, cb) {
    return {
        type: types.W25F2000_DELETE_DATA_GRID,
        params,
        cb
    }
}
