
import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W25F1000_actions";
import { delay } from "redux-saga";

export default function W25F1000Sagas() {
    return [
        watchGetCboRecruitmentChanel(),
        watchAdd(),
        watchGetCboTabWorkFlow(),
        watchGetDataPopup(),
        watchEdit(),
        watchConfirm(),
    ];
}

export function* getCboRecruitmentChanel(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w25f6000/get-cbo-recruitment-chanel");
        if (response && response.data) {
            yield put({ type: types.W25F1000_GET_CBO_RECRUITMENT_CHANEL_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get Cbo Recruitment Chanel error",e);
    }
}

export function* watchGetCboRecruitmentChanel() {
    while (true) {
        const watcher = yield takeLatest(types.W25F1000_GET_CBO_RECRUITMENT_CHANEL, getCboRecruitmentChanel);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboTabWorkFlow(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2011/get-tab-work-flow",data.params);
        if (response && response.data) {
            yield put({ type: types.W25F1000_GET_CBO_TAB_WORK_FLOW_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get Cbo Recruitment Chanel error",e);
    }
}

export function* watchGetCboTabWorkFlow() {
    while (true) {
        const watcher = yield takeLatest(types.W25F1000_GET_CBO_TAB_WORK_FLOW, getCboTabWorkFlow);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getDataPopup(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f1000/load-popup",data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get Data Popup error",e);
    }
}

export function* watchGetDataPopup() {
    while (true) {
        const watcher = yield takeLatest(types.W25F1000_GET_DATA_POPUP, getDataPopup);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* add(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/w25f1000/add",data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("saved Data error",e);
    }
}

export function* watchAdd() {
    while (true) {
        const watcher = yield takeLatest(types.W25F1000_ADD_DATA, add);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* edit(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f1000/edit",data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("saved Data error",e);
    }
}

export function* watchEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W25F1000_EDIT_DATA, edit);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* confirm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f1000/confirm",data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("confirm data error",e);
    }
}

export function* watchConfirm() {
    while (true) {
        const watcher = yield takeLatest(types.W25F1000_CONFIRM_DATA, confirm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}