
import { types } from "./W25F2018_actions";

const initialState = {
    getListCandicates: [],
    getCboFilterCandidate: [],
    getCboInterview: [],
    getCboStage: []

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W25F2018_GET_LIST_CANDICATE_SUCCESS:
            return {
                ...state,
                getListCandicates: action.data
            };
        case types.W25F2018_LOAD_CBO_FILTER_CANDIDATE_SUCCESS:
            return {
                ...state,
                getCboFilterCandidate: action.data
            };
        case types.W25F2018_LOAD_CBO_INTERVIEW_SUCCESS:
            return {
                ...state,
                getCboInterview: action.data
            };
        case types.W25F2018_LOAD_CBO_STAGE_SUCCESS:
            return {
                ...state,
                getCboStage: action.data
            };
        default:
            return state;
    }
}
