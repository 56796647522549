/**
 * @copyright 2021 @ DigiNet
 * @author QUOCUY
 * @create 5/11/2020
 * @Example
 */

export const types = {
    W25F2040_GET_SALARY_CAPTION: "W25F2040_GET_SALARY_CAPTION",
    W25F2040_GET_SALARY_CAPTION_SUCCESS: "W25F2040_GET_SALARY_CAPTION_SUCCESS",
    W25F2040_GET_COEFFICIENT_CAPTION: "W25F2040_GET_COEFFICIENT_CAPTION",
    W25F2040_GET_COEFFICIENT_CAPTION_SUCCESS: "W25F2040_GET_COEFFICIENT_CAPTION_SUCCESS",
    W25F2040_GET_CBO_TRIAL_PERIOD: "W25F2040_GET_CBO_TRIAL_PERIOD",
    W25F2040_GET_CBO_TRIAL_PERIOD_SUCCESS: "W25F2040_GET_CBO_TRIAL_PERIOD_SUCCESS",
    W25F2040_GET_CBO_DIRECT_MANAGER: "W25F2040_GET_CBO_DIRECT_MANAGER",
    W25F2040_GET_CBO_DIRECT_MANAGER_SUCCESS: "W25F2040_GET_CBO_DIRECT_MANAGER_SUCCESS",
    W25F2040_GET_CBO_SALARY_GROUP: "W25F2040_GET_CBO_SALARY_GROUP",
    W25F2040_GET_CBO_SALARY_GROUP_SUCCESS: "W25F2040_GET_CBO_SALARY_GROUP_SUCCESS",
    W25F2040_GET_CBO_RECRUITMENT_FILE: "W25F2040_GET_CBO_RECRUITMENT_FILE",
    W25F2040_GET_CBO_RECRUITMENT_FILE_SUCCESS: "W25F2040_GET_CBO_RECRUITMENT_FILE_SUCCESS",
    W25F2040_LOAD_VOUCHER: "W25F2040_LOAD_VOUCHER",
    W25F2040_LOAD_VOUCHER_SUCCESS: "W25F2040_LOAD_VOUCHER_SUCCESS",
    W25F2040_ADD: "W25F2040_ADD",
    W25F2040_ADD_SUCCESS: "W25F2040_ADD_SUCCESS",
    W25F2040_EDIT: "W25F2040_EDIT",
    W25F2040_EDIT_SUCCESS: "W25F2040_EDIT_SUCCESS",
    W25F2040_CHECK_HIDE_SAL_OFFER_RI: "W25F2040_CHECK_HIDE_SAL_OFFER_RI",
};

export function loadSalaryCaption(params, cb) {
    return {
        type: types.W25F2040_GET_SALARY_CAPTION,
        params,
        cb,
    };
}

export function loadCoefficientCaption(params, cb) {
    return {
        type: types.W25F2040_GET_COEFFICIENT_CAPTION,
        params,
        cb,
    };
}

export function getCboTrialPeriod(params, cb) {
    return {
        type: types.W25F2040_GET_CBO_TRIAL_PERIOD,
        params,
        cb,
    };
}

export function getCboDirectManager(params, cb) {
    return {
        type: types.W25F2040_GET_CBO_DIRECT_MANAGER,
        params,
        cb,
    };
}

export function getCboRecruitmentFile(params, cb) {
    return {
        type: types.W25F2040_GET_CBO_RECRUITMENT_FILE,
        params,
        cb,
    };
}

export function getCboSalaryGroup(cb) {
    return {
        type: types.W25F2040_GET_CBO_SALARY_GROUP,
        cb,
    };
}

export function loadVoucher(params, cb) {
    return {
        type: types.W25F2040_LOAD_VOUCHER,
        params,
        cb,
    };
}

export function add(params, cb) {
    return {
        type: types.W25F2040_ADD,
        params,
        cb,
    };
}

export function edit(params, cb) {
    return {
        type: types.W25F2040_EDIT,
        params,
        cb,
    };
}

export function checkHideSalOfferRI(cb) {
    return {
        type: types.W25F2040_CHECK_HIDE_SAL_OFFER_RI,
        cb,
    };
}
