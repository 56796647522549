/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 09/18/2020
 * @Example 
 */

import { types } from "./W25F2015_actions";

const initialState = {
    getListEmployment: [],

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W25F2015_LOAD_INFO_EMPLOYMENT_SUCCESS:
            return {
                ...state,
                getListEmployment: action.data
            };

        default:
            return state;
    }
}
